@import 'vars';

/** FORM **/
.form {
  &__title {
    font-size: 32px;
    margin: 0;
  }
  &__body {
    margin-top: 32px;
  }
  &__group {
    position: relative;
    margin: 32px 0;
    &_focus {
      &--on {
        input, textarea {
          color: $orangeColor;
          border: 1px solid $orangeColor;
        }
        &.image-group {
          input {
            border: none;
          }
        }
      }
    }
    &_text {
      &--on {
        input, textarea {
          color: $orangeColor;
          border: 1px solid $orangeColor;
        }
        &.image-group {
          input {
            border: none;
          }
        }
      }
    }
    &_margin {
      &--0 {
        margin: 0;
      }
    }
  }
  &__input, &__select {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    font-size: 16px;
    padding: 0 16px;
    outline: none;
    border: 1px solid $lightGrayColor;
    border-radius: 2px;
  }
  &__input {
    &_type {
      &--file {
        height: auto;
        border: none;
        padding: 0;
      }
      &--color {
        max-width: 48px;
        background-color: $whiteColor;
        padding: 0;
      }
    }
  }
  &__textarea {
    box-sizing: border-box;
    width: 100%;
    height: 128px;
    font-size: 16px;
    border: 1px solid #d6d8e0;
    border-radius: 2px;
    padding: 16px;
    resize: none;
    &:focus {
      outline: none;
    }
  }
  &__label {
    display: block;
    color: $blackColor;
    line-height: 16px;
    margin-bottom: 8px;
    &_margin {
      &--0 {
        margin: 0;
      }
    }
  }
  &__errors {
    font-size: 12px;
    color: $redColor;
    margin: 8px 0 0 0;
  }
  &__error {
    display: none;
    &_type {
      &--validation {
        display: block;
        font-size: 16px;
      }
    }
  }
  &__button {
    margin: 40px 0 0 0;
  }
  .image-group {
    &__banner {
      img {
        width: 100%;
        max-width: 100%;
        max-height: 360px;
        object-fit: cover;
      }
    }
    &__gallery {
      img {
        width: 100%;
        max-height: 160px;
        object-fit: contain;
      }
    }
  }
  &__preview {
    border: 1px solid $lightGrayColor;
  }
}
/** FORM END **/

/** CHECKBOX LABEL **/
.checkbox-label {
  &__input {
    position: absolute;
    margin: 0;
    z-index: -1;
    opacity: 0;
    &:checked + .checkbox-label__link:before {
      background: $orangeColor;
      border-color: $orangeColor;
    }
  }
  &__link {
    position: relative;
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 0 0 30px;
    cursor: pointer;
    & > label {
      cursor: pointer;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      background: $whiteColor;
      border: 1px solid $lightGrayColor;
      border-radius: 2px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 6px;
      left: 6px;
      height: 6px;
      width: 11px;
      border-top: 2px solid $whiteColor;
      border-right: 2px solid $whiteColor;
      transform: rotate(135deg);
      transition: all 0.2s ease-out 0s;
      opacity: 1;
    }
  }
}
/** CHECKBOX LABEL END **/

/** REGION SELECTION **/
.region-selection {
  box-shadow: 0 5px 25px rgba(48, 34, 31, 0.07);
}
/** REGION SELECTION END **/

/** ADDITIONAL UNIT END **/
.additional-unit {
  &__title {
    &_bold {
      &--no {
        font-weight: 400;
      }
    }
  }
}
/** ADDITIONAL UNIT END **/

/** POLICY **/
.policy {
  &__link {
    font-size: 14px;
    transition: color 0.2s;
    &:hover {
      color: $orangeColor;
    }
  }
}
/** POLICY END **/